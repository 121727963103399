import React, { useCallback, useEffect, useState } from "react";
import { FiHome, FiSettings } from "react-icons/fi";
import Projects from "./projects";
import Billings from "./billings";
import { withRouter } from "../../authentication/withRouter";
import { LuWallet } from "react-icons/lu";
import Settings from "./settings";
import AppLogo from "../../assets/app_logo.png";
import {
  checkPersonalizationStatus,
  getAllPlansAPI,
  getCurrentPlan,
  getProjectsOfUserAPI,
  getUserDetailsAPI,
  personalizationHandlerAPI
} from "../../service/api";
import Loading from "../utils/loading";
import { ProjectModelDTO, convertToProjectModelDTO } from "../utils/DTO";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonalizeRootState,
  setPersonalizationStatus
} from "../utils/personalizeSlice";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { BillingPlansList, CardDetail, PlanModel, User } from "../utils/types";
import { UserRootState, setUser } from "../utils/userSlice";

/**
 * Dashboard component serves as the main interface for the application.
 *
 * @param {RouterProps} props - RouterProps containing routing information.
 * @returns {JSX.Element} Component for managing projects, billings, and settings.
 */
let Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const { user } = useAuth0<User>();
  const [cards] = useState<CardDetail[]>([]);
  const [message, setMessage] = useState("");
  const [projects, setProjects] = useState<ProjectModelDTO[]>([]);
  const [plans, setPlans] = useState<PlanModel[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");

  const dispatch = useDispatch();
  const personalizeStatus = useSelector(
    (state: PersonalizeRootState) => state.personalize.status
  );
  const userDetail = useSelector((state: UserRootState) => state.user);

  const fetchUserDetails = async () => {
    const res = await getUserDetailsAPI(user?.email ?? "");
    if (res.success && res.data) {
      dispatch(
        setUser({
          userId: res.data._id,
          name: res.data.first_name
        })
      );
    }
  };

  const fetchPlans = async () => {
    const res = await getAllPlansAPI();
    if (res.success && res.data) {
      setPlans(res.data);
    }
  };

  const fetchProjects = async () => {
    if (user?.sub) {
      const userId = userDetail.userId;
      const res = await getProjectsOfUserAPI(userId);
      if (res.data) {
        setProjects(res.data.data.map(convertToProjectModelDTO));
      }
    }
  };

  const fetchCurrentPlan = async () => {
    if (user?.sub) {
      const userId = userDetail.userId;
      const res = await getCurrentPlan(userId);
      if (res.status === 200 && res.data) {
        setCurrentPlan(res.data);
      }
    }
  };

  const fetchPersonalizationStatus = async () => {
    if (user?.sub) {
      const user_id = userDetail.userId;
      if (personalizeStatus === "in_progress") return;
      const res = await checkPersonalizationStatus(user_id);
      if (res.status === 200 && res.data) {
        dispatch(setPersonalizationStatus("completed"));
      } else {
        try {
          await personalizationHandlerAPI(dispatch, user_id ?? "");
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setMessage("Loading...");
    await fetchUserDetails();
    await fetchPlans();
    await fetchProjects();
    await fetchCurrentPlan();
    await fetchPersonalizationStatus();
    setLoading(false);
  }, [user]);

  const currentComponent = localStorage.getItem("currentComponent");

  useEffect(() => {
    if (currentComponent === "settings") {
      handleItemClick("settings");
    } else if (currentComponent === "billings") {
      handleItemClick("billings");
    } else {
      handleItemClick("projects");
    }

    fetchData();
  }, [fetchData, currentComponent]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [activeItem, setActiveItem] = useState(
    location?.state?.tab || "projects"
  );

  location?.state?.tab && setActiveItem(location?.state?.tab);

  const renderMainContent = () => {
    switch (activeItem) {
      case "projects":
        return <Projects currentPlan={currentPlan} projects={projects} />;
      case "billings":
        return (
          <Billings
            currentPlan={currentPlan}
            userId={userDetail.userId ?? ""}
            plans={plans}
            cards={cards}
          />
        );
      case "settings":
        return <Settings currentPlan={currentPlan} />;
      default:
        return <Projects />;
    }
  };

  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  return (
    <div className="flex h-screen text-sm">
      {/* Sidebar */}
      <div
        className={`border-r-2 fixed top-0 bottom-0 w-32  md:w-48  ${
          sidebarOpen ? "block" : "hidden"
        }`}
      >
        {/* logo */}
        <div className="flex h-screen flex-col  text-black">
          {isLoading && (
            <div className="w-[95%] text-sm">
              {isLoading && <Loading message={message} />}{" "}
            </div>
          )}
          <div>
            <div className="my-7 flex pl-[12px] lg-pl-[16px] text-black">
              <img src={AppLogo} className="aspect-auto w-[44px]" />
            </div>
            <ul className="space-y-5 flex flex-col w-[95%] md:w-[90%] m-auto font-semibold text-neutral-800">
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "projects");
                  handleItemClick("projects");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded ${
                  activeItem === "projects"
                    ? "bg-gray_bg font-800"
                    : "hover:bg-gray_bg"
                }`}
              >
                {" "}
                <FiHome /> <span>Projects</span>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "billings");
                  handleItemClick("billings");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded ${
                  activeItem === "billings"
                    ? "bg-gray_bg font-800"
                    : "hover:bg-gray_bg"
                }`}
              >
                {" "}
                <LuWallet /> <span>Billings</span>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "settings");
                  handleItemClick("settings");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded ${
                  activeItem === "settings"
                    ? "bg-gray-100 font-800"
                    : "hover:bg-gray_bg"
                }`}
              >
                <FiSettings /> <span>Settings</span>
              </li>
            </ul>
          </div>
          <div className="profile flex flex-col md:flex-row md:space-x-3 md:ml-5 items-center mt-auto mb-2 md:mb-5">
            <div className="bg-prim rounded-full text-white h-95 w-95 grid place-items-center">
              {user?.given_name?.charAt(0).toUpperCase()}
              {user?.family_name?.charAt(0).toUpperCase()}
            </div>
            <div className="flex mt-2 flex-col">
              <span
                className="ml-1"
                onClick={() => {
                  localStorage.setItem("currentComponent", "settings");
                  handleItemClick("settings");
                }}
              >
                {user?.given_name || "You"}
              </span>
              <button
                className="bg-[#4338CA] long-button w-24 m-1 text-xs p-1"
                onClick={() => {
                  localStorage.setItem("currentComponent", "billings");
                  handleItemClick("billings");
                }}
              >
                {currentPlan === process.env.REACT_APP_PRO_PRODUCT
                  ? BillingPlansList.Pro
                  : "Upgrade Plan"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Area */}
      <div
        className={`flex-grow px-2 md:px-6 pt-3 ${
          sidebarOpen ? "ml-32 md:ml-48" : "ml-8"
        }`}
      >
        {renderMainContent()}
      </div>

      {/* Toggle Button */}
      <div
        className={`fixed top-[18px] ${
          sidebarOpen ? " ml-24 sm:ml-30 md:ml-36" : "ml-4"
        } mt-3 md:mt-2 text-black rounded-full p-2`}
        onClick={toggleSidebar}
      >
        {sidebarOpen ? (
          <svg
            className=" w-4 h-4 md:w-6 md:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

Dashboard = withRouter(Dashboard);
export default Dashboard;

import { configureStore } from "@reduxjs/toolkit";
import episodeReducer from "./episodeSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import personalizeReducer from "./personalizeSlice";
import userReducer from "./userSlice";

// Define the Redux Persist configuration
const persistConfig = {
  key: "root", // The key in local storage
  storage
};

// Create a persisted reducer using the episodeReducer and persistConfig
const persistedEpisodeReducer = persistReducer(persistConfig, episodeReducer);
const persistedPersonalizeReducer = persistReducer(
  persistConfig,
  personalizeReducer
);
const persistedUserReducer = persistReducer(persistConfig, userReducer);
// Create the Redux store with the persisted reducer
const store = configureStore({
  reducer: {
    episode: persistedEpisodeReducer,
    personalize: persistedPersonalizeReducer,
    user: persistedUserReducer
  }
});

// Create a persistor to persist the store
const persistor = persistStore(store);

export { store, persistor };

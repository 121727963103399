import React, { useState } from "react";
import { addCard } from "../../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useAuth0, User } from "@auth0/auth0-react";

type CardModalProps = {
  onClose: () => void;
};

/**
 * CardModal Component - A modal for adding payment card details.
 *
 * @param {Object} props - Props for the CardModal component.
 * @param {Function} props.onClose - Function to close the modal.
 */
const CardModal = (props: CardModalProps) => {
  const onClose = props.onClose;
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [name, setName] = useState("");
  const { user } = useAuth0<User>();
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const payload = {
      card_number: cardNumber,
      expiry: expiry,
      name: name,
      cvv: "",
      isDefault: false,
      userID: user?._id ?? ""
    };

    try {
      await addCard(payload);
      onClose();
    } catch (error) {
      // Handle error state or display an error message to the user
    }
  };

  return (
    <div className="fixed p-4 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-75">
      <div className="bg-white px-8 py-4 rounded-md shadow-md w-80">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold ">Add Payment Method</h2>
          <button
            className="text-gray-600 hover:text-gray-800 transition ease-in-out duration-150"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </div>
        <form className="p-4" onSubmit={handleSubmit}>
          <label className="block mb-2">Card Number</label>
          <input
            type="text"
            className="w-full border px-2 py-1 mb-3"
            value={cardNumber}
            onChange={e => setCardNumber(e.target.value)}
            placeholder="XXXX XXXX XXXX XXXX"
            required
          />
          <label className="block mb-2">Card Expiry</label>
          <input
            type="date"
            className="w-full border px-2 py-1 mb-3"
            value={expiry}
            onChange={e => setExpiry(e.target.value)}
            required
          />
          <label className="block mb-2">Card Holder Name</label>
          <input
            type="text"
            className="w-full border px-2 py-1 mb-3"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="John Doe"
            required
          />
          <br></br>
          <button
            type="submit"
            className="bg-blue-500 text-white mt-3  px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Add Payment Method
          </button>
        </form>
      </div>
    </div>
  );
};

export default CardModal;

import { FiHome, FiSettings } from "react-icons/fi";
import React from "react";
import { LuWallet } from "react-icons/lu";
import AppLogo from "../../assets/app_logo.png";
import { NavbarProps, User } from "../utils/types";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Navbar component responsible for rendering the sidebar navigation.
 *
 * @param {NavbarProps} props - NavbarProps containing navigation and user information.
 * @returns {JSX.Element} Component for sidebar navigation.
 */
export const Navbar = (props: NavbarProps) => {
  const { user } = useAuth0<User>();
  const sidebarOpen = props.sidebarOpen;
  const router = props.props.router;

  const handleClick = (item: string) => {
    router.navigateWithData("/", item);
  };

  return (
    <>
      <div
        className={`border-r-2 fixed top-0 bottom-0 w-32  md:w-48  ${
          sidebarOpen ? "block" : "hidden"
        }`}
      >
        {/* logo */}
        <div className="flex h-screen flex-col  text-black">
          <div>
            <div className="my-7 flex pl-[12px] lg-pl-[16px] text-black">
              <img src={AppLogo} className="aspect-auto w-[44px]" />
            </div>
            <ul className="space-y-5 flex flex-col w-[95%] md:w-[90%] m-auto font-semibold text-neutral-800">
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "projects");
                  handleClick("projects");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded hover:bg-gray_bg`}
              >
                {" "}
                <FiHome /> <span>Projects</span>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "billings");
                  handleClick("billings");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded hover:bg-gray_bg`}
              >
                <LuWallet /> <span>Billing</span>
              </li>
              <li
                onClick={() => {
                  localStorage.setItem("currentComponent", "settings");
                  handleClick("settings");
                }}
                className={`flex cursor-pointer items-center space-x-2 p-3 my-1 font-600 rounded hover:bg-gray_bg`}
              >
                <FiSettings /> <span>Settings</span>
              </li>
            </ul>
          </div>
          <div className="profile flex flex-col md:flex-row md:space-x-3 md:ml-5 items-center mt-auto mb-2 md:mb-5">
            <div className="bg-prim rounded-full text-white h-95 w-95 grid place-items-center">
              {user?.given_name?.charAt(0).toUpperCase()}
              {user?.family_name?.charAt(0).toUpperCase()}
            </div>
            <div>
              <span className="ml-1" onClick={() => handleClick("settings")}>
                {user?.given_name}
              </span>
              <button
                className="bg-[#4338CA] long-button w-24 m-1 text-xs p-1"
                onClick={() => handleClick("billings")}
              >
                Upgrade plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "../../authentication/withRouter";
import { BsClipboard } from "react-icons/bs";
import { Navbar } from "../dashboard/navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  editEpisodeFields,
  editEpisodeTitle,
  getEpisodeById
} from "../../service/api";
import Loading from "../utils/loading";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DynamicTextArea from "../utils/dynamicTextArea";
import { AllEpisodesProps, EpisodeModel } from "../utils/types";

/**
 * Component to display and edit generated content of an episode.
 * @param {AllEpisodesProps} props - Props containing router, currentPlan, and projects.
 */
function GeneratedContentWithoutRouter(props: AllEpisodesProps) {
  const [episode, setEpisode] = useState<EpisodeModel>({
    _id: "",
    user_id: "",
    url: "",
    is_title_needed: false,
    is_transcript_needed: false,
    is_description_needed: false,
    is_timestamp_needed: false,
    is_engaging_needed: false,
    is_episode_completed: false,
    is_linked_post_needed: false,
    is_tweet_thread_needed: false,
    is_self: false,
    title: "",
    generated_titles: "",
    description: "",
    timestamp: [],
    engaging_snippet: [],
    tweet_thread: "",
    linkedin_post: "",
    context: "",
    transcript: "",
    reference_type: "",
    project_id: "",
    secondary_reference: "",
    duplicationId: "",
    words: [
      {
        text: "",
        start: 0,
        end: 0,
        confidence: 0,
        speaker: ""
      }
    ],
    transcriptId: "",
    image: "",
    isDeleted: false,
    deletedAt: null
  });

  const [newTitle, setIsNewTitle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(episode?.title);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [transcriptData, setTranscriptData] = useState<string>();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [fields, setFields] = useState({
    titles: "",
    description: "",
    timestamps: "",
    tweetThread: "",
    linkedinPost: "",
    engagingSnippets: ""
  });

  const [editedTitles, setEditedTitles] = useState(fields.titles);
  const [editedDescription, setEditedDescription] = useState(
    fields.description
  );
  const [editedTimestamps, setEditedTimestamps] = useState(fields.timestamps);
  const [editedTweetThread, setEditedTweetThread] = useState(
    fields.tweetThread
  );
  const [editedLinkedinPost, setEditedLinkedinPost] = useState(
    fields.linkedinPost
  );
  const [editedEngagingSnippets, setEditedEngagingSnippets] = useState(
    fields.engagingSnippets
  );

  const { id } = useParams();

  const showToastMessage = () => {
    toast.success("Text Copied !", {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  };

  const handleKeyPress = async (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (editedTitle === "") {
      setIsEditing(false);
      setEditedTitle(episode?.title);
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      await handleSaveClick();
    }
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleEditCloseClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    await updateTitle(editedTitle);
    setIsEditing(false);
  };

  const updateTitle = async (newTitle: string) => {
    const data = { title: newTitle, id: episode._id };
    const res = await editEpisodeTitle(data);

    if (res.success) {
      setIsNewTitle(true);
    }
  };

  const handleCopy = () => {
    showToastMessage();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleEdit = async (
    fieldName: string,
    editedContent: React.SetStateAction<string>
  ) => {
    // Handle editing of fields
    if (fieldName === "titles") {
      setEditedTitles(editedContent);
    } else if (fieldName === "description") {
      setEditedDescription(editedContent);
    } else if (fieldName === "timestamps") {
      setEditedTimestamps(editedContent);
    } else if (fieldName === "engagingSnippets") {
      setEditedEngagingSnippets(editedContent);
    } else if (fieldName === "linkedinPost") {
      setEditedLinkedinPost(editedContent);
    } else if (fieldName === "tweetThread") {
      setEditedTweetThread(editedContent);
    }
  };

  const handleSave = async (fieldName: string, value: string) => {
    // Handle saving of edited fields
    await editEpisodeFields(fieldName, value, episode._id);
  };

  useEffect(() => {
    const fetchEpisode = async () => {
      setLoading(true);
      setMessage("Loading Content...");

      try {
        const res = await getEpisodeById(id !== undefined ? id : "");
        const episode = res.data?.episode,
          fields = res.data?.fields;
        if (episode && fields) {
          setEpisode(episode);
          setFields(fields);
          setEditedTitle(episode?.title);
          setTranscriptData(JSON.parse(episode?.transcript));
        }
      } catch (error) {
        //console.error("Error fetching episode:", error);
      }

      setLoading(false);
    };

    fetchEpisode();
  }, [id, newTitle]);

  return (
    <div className="flex h-screen text-sm">
      {sidebarOpen && <Navbar sidebarOpen={sidebarOpen} props={props} />}
      {/* Main Area */}
      {isLoading && (
        <div className="w-[95%] text-sm">
          {isLoading && <Loading message={message} />}{" "}
        </div>
      )}
      <div
        className={`flex-grow px-2 md:px-6 pt-3 ${
          sidebarOpen ? "ml-32 md:ml-48" : "ml-8"
        }`}
      >
        <ToastContainer autoClose={1000} hideProgressBar />
        <div className="w-[95%] mx-auto space-y-10 my-5 text-sm">
          <div className=" my-5 py-8">
            {isEditing ? (
              <div>
                <div className="flex space-x-3">
                  <input
                    className="heading w-5/6 focus:outline-none"
                    type="text"
                    id="title"
                    name="title"
                    value={editedTitle}
                    onChange={e => setEditedTitle(e.target.value)}
                    onKeyUpCapture={handleKeyPress}
                  />

                  <button onClick={handleEditCloseClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex">
                <h3 className="heading">{episode?.title}</h3>
                <span className=" mx-2 my-2 w-2" onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </span>
              </div>
            )}
            <br />
          </div>

          {fields.titles == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="title" className="input-label">
                Episode Titles
              </label>
              <div className="flex space-x-3">
                <DynamicTextArea
                  content={fields.titles}
                  setFields={setFields}
                  fieldName="titles"
                  handleEdit={editedContent =>
                    handleEdit("titles", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.titles}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("titles", editedTitles);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {fields.description == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="description" className="input-label">
                Episode Description
              </label>
              <div className="flex space-x-3">
                <DynamicTextArea
                  content={fields.description}
                  setFields={setFields}
                  fieldName="description"
                  handleEdit={editedContent =>
                    handleEdit("description", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.description}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("description", editedDescription);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {fields.timestamps == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="timestamps" className="input-label">
                Timestamps
              </label>
              <div className="flex space-x-3">
                <DynamicTextArea
                  content={fields.timestamps}
                  setFields={setFields}
                  fieldName="timestamps"
                  handleEdit={editedContent =>
                    handleEdit("timestamps", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.timestamps}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("timestamps", editedTimestamps);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}
          {transcriptData == "" || episode?.is_transcript_needed == false ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="title" className="input-label">
                Episode Transcript
              </label>
              <div className="flex space-x-3">
                <div
                  className="input w-full md:w-11/12 h-60 overflow-auto"
                  // type="text"
                  id="title"
                  // name="title"
                  // value={episode?.transcript}
                  // value={transcriptData}
                >
                  {Array.isArray(transcriptData) ? (
                    transcriptData.map((item, index) => (
                      <p key={index}>
                        <span className="text-[#7C3AED]">
                          {`${item.start} - ${item.end} Speaker ${item.speaker}: `}
                        </span>
                        {item.text}
                      </p>
                    ))
                  ) : (
                    <p>{episode?.transcript}</p>
                  )}
                </div>
                <CopyToClipboard
                  text={episode?.transcript}
                  onCopy={handleCopy}
                  // className="text-sm text-subtext mt-5"
                >
                  <div className="text-subtext" style={{ marginTop: "5px" }}>
                    <BsClipboard />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          )}
          {fields.engagingSnippets == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="description" className="input-label">
                Engaging Snippets
              </label>
              <div className="flex space-x-3">
                <DynamicTextArea
                  content={fields.engagingSnippets}
                  setFields={setFields}
                  fieldName="engagingSnippets"
                  handleEdit={editedContent =>
                    handleEdit("engagingSnippets", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.engagingSnippets}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("titles", editedEngagingSnippets);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {fields.tweetThread == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="tweetThread" className="input-label">
                Tweet Thread
              </label>
              <div className="flex space-x-3">
                <DynamicTextArea
                  content={fields.tweetThread}
                  setFields={setFields}
                  fieldName="tweetThread"
                  handleEdit={editedContent =>
                    handleEdit("tweetThread", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.tweetThread}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("titles", editedTweetThread);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {fields.linkedinPost == "" ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="linkedinPost" className="input-label">
                LinkedIn Post
              </label>
              <div className="flex space-x-3 pb-10">
                <DynamicTextArea
                  content={fields.linkedinPost}
                  setFields={setFields}
                  fieldName="linkedinPost"
                  handleEdit={editedContent =>
                    handleEdit("linkedinPost", editedContent)
                  }
                />
                <div className="flex flex-col">
                  <CopyToClipboard
                    text={fields.linkedinPost}
                    onCopy={handleCopy}
                    // className="text-sm text-subtext mt-5"
                  >
                    <div className="text-subtext" style={{ marginTop: "5px" }}>
                      <BsClipboard />
                    </div>
                  </CopyToClipboard>
                  <button
                    className="my-3"
                    onClick={() => {
                      handleSave("titles", editedLinkedinPost);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`fixed top-[18px] ${
          sidebarOpen ? " ml-24 sm:ml-30 md:ml-36" : "ml-4"
        } mt-3 md:mt-2 text-black rounded-full p-2`}
        onClick={toggleSidebar}
      >
        {sidebarOpen ? (
          <svg
            className=" w-4 h-4 md:w-6 md:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </div>
    </div>
  );
}

const GeneratedContent = withRouter(GeneratedContentWithoutRouter);
export default GeneratedContent;

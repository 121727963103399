import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";

export type DisplayFields = {
  titles: string;
  description: string;
  timestamps: string;
  tweetThread: string;
  linkedinPost: string;
  engagingSnippets: string;
};

export type DynamicTextArea = {
  content: string;
  setFields: Dispatch<SetStateAction<DisplayFields>>;
  fieldName: keyof DisplayFields;
  handleEdit: (editedContent: SetStateAction<string>) => void;
};

/**
 * A dynamic textarea component that adjusts its height based on content and provides
 * a way to handle text input changes.
 *
 * @param {object} props - Component props.
 * @param {string} props.content - The content to display in the textarea.
 * @param {function} props.setFields - A function to set field values in the parent component's state.
 * @param {string} props.fieldName - The name of the field associated with the textarea.
 * @param {function} props.handleEdit - A function to handle text input changes.
 * @returns {JSX.Element} - The rendered JSX element.
 */

function DynamicTextArea(props: DynamicTextArea) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textareaRef.current) {
      const contentHeight = textareaRef.current.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(textareaRef.current).lineHeight
      );
      const numberOfLines = Math.floor(contentHeight / lineHeight);
      textareaRef.current.rows = numberOfLines;
    }
  }, [props.content]);

  const handleInputChange = (e: { target: { value: string } }) => {
    const editedContent = e.target.value;
    props.setFields(prevFields => ({
      ...prevFields,
      [props.fieldName]: editedContent
    }));
    props.handleEdit(editedContent);
  };

  return (
    <textarea
      ref={textareaRef}
      className="input w-full md:w-11/12 min-h-[20px] resize-none overflow-hidden h-auto "
      value={props.content}
      onChange={handleInputChange}
    />
  );
}

export default DynamicTextArea;

import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "../../authentication/withRouter";
import OR from "../utils/or";
import { BsUpload } from "react-icons/bs";
import { AiOutlineInfoCircle, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import SwitchButton from "../utils/switch";
import { getAllSecondaryRef, newEpisodeHandlerAPI } from "../../service/api";
import { v4 as uuid } from "uuid";
import Loading from "../utils/loading";
import { Navbar } from "../dashboard/navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState, addProcessingEpisode } from "../utils/episodeSlice";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { AllEpisodesProps, EpisodeFormData, User } from "../utils/types";
import { EpisodeModelDTO } from "../utils/DTO";
import { useAuth0 } from "@auth0/auth0-react";
import { PersonalizeRootState } from "../utils/personalizeSlice";
import { UserRootState } from "../utils/userSlice";

/**
 * Component for creating a new episode.
 *
 * @param {AllEpisodesProps} props - Component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
function NewEpisodeWithoutRouter(props: AllEpisodesProps) {
  let { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [_episode, setEpisode] = useState<EpisodeModelDTO>();
  const { user } = useAuth0<User>();
  const [error, setError] = useState<string | undefined>("");
  const [secondaryReferences, setSecondaryReferences] = useState<
    {
      name: string;
      rss_writing_style: string;
      twitter_writing_style: string;
      linkedin_writing_style: string;
    }[]
  >([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showToast, _setShowToast] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  const userDetail = useSelector((state: UserRootState) => state.user);

  const handleChipClick = (label: string) => {
    setSelectedLabel(label);
    formData.context = selectedLabel;
  };

  const _state = useSelector(
    (state: RootState) => state.episode.processingEpisodes
  );

  const isPersonalized = useSelector(
    (state: PersonalizeRootState) => state.personalize.status
  );
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [formData, setFormData] = useState<EpisodeFormData>({
    isTitleNeeded: false,
    isDescriptionNeeded: false,
    isTranscriptNeeded: false,
    isTimestampNeeded: false,
    isEngagingNeeded: false,
    isLinkedPostNeeded: false,
    isTweetThreadNeeded: false,
    isSelf: false,
    youtubeUrl: "",
    youtubeVideo: null,
    primaryReference: "",
    secondaryReference: "",
    context: "",
    noOfSpeaker: -1
  });

  const router = useNavigate();
  useEffect(() => {
    const fetchSecRef = async () => {
      const res = await getAllSecondaryRef();
      if (res.success && res.data) {
        setSecondaryReferences(res.data);
      }
    };
    fetchSecRef();
  }, [user]);

  const validateData = (formData: any) => {
    if (formData) return true;
    return true;
  };

  type ExtendedFormData = typeof formData & {
    duplicationId: string;
  };

  const handleGenerate = async () => {
    if (validateData(formData)) {
      if (userDetail.userId) {
        setLoading(true);
        const data: ExtendedFormData = {
          ...formData,
          duplicationId: uuid()
        };
        const payload = {
          projectId: id,
          title:
            data.youtubeUrl == undefined
              ? data.youtubeVideo?.name ?? ""
              : data.youtubeUrl,
          status: "processing",
          duplicationId: data.duplicationId
        };
        dispatch(addProcessingEpisode(payload));

        setLoading(true);
        setMessage("Initializing Project");
        id = id === undefined ? "" : id;
        const res = await newEpisodeHandlerAPI(
          { ...formData, userId: userDetail.userId },
          setLoading,
          setEpisode,
          setMessage,
          props,
          id,
          dispatch
        );
        if (res.success && res.data) {
          setMessage("Project Initialized");
          setLoading(false);
          router(`/episodes/${res.data.projectId}`);
        } else {
          setError(res.error);
          setLoading(false);
        }
      } else {
        setError("Please Login To Continue");
        setLoading(false);
      }
    }
  };

  const handleSwitchChange = (fieldName: string, value: boolean) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: value
    }));

    if (fieldName === "isSelf") {
      const selectElement = document.querySelector(
        ".primary_reference"
      ) as HTMLSelectElement;
      if (selectElement) {
        if (value) {
          selectElement.selectedIndex = 0;
          selectElement.disabled = true;
          formData.secondaryReference = "";
        } else {
          selectElement.disabled = false;
        }
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: any) => {
    const selectedFile = event.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      youtubeVideo: selectedFile
    }));

    setUploadedFile(selectedFile.name);
  };

  return (
    <div className="flex h-screen text-sm">
      {sidebarOpen && <Navbar sidebarOpen={sidebarOpen} props={props} />}
      {/* Main Area */}
      <div
        className={`flex-grow px-2 md:px-6 pt-3 ${
          sidebarOpen ? "ml-32 md:ml-48" : "ml-8"
        }`}
      >
        {isLoading && (
          <div className="w-[95%] text-sm">
            {/* Other JSX code */}
            {isLoading && <Loading message={message} />}{" "}
            {/* Pass the message to the Loading component */}
          </div>
        )}
        {showToast && (
          <div
            className={`fixed top-0 left-1/2 transform -translate-x-1/2 z-50 transition-opacity transition-visibility duration-300 ${
              showToast ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <div
              id="toast-success"
              className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-slate-400 rounded-lg shadow"
              role="alert"
            >
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="sr-only">Check icon</span>
              </div>
              <div className="ml-3 text-sm font-normal">
                We will notify you on your mail soon when files are ready.
              </div>
              <button
                type="button"
                className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
                data-dismiss-target="#toast-success"
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        <div className="w-[95%] mx-auto space-y-10 my-5 text-sm">
          {error && <div className="error-message my-5">{error}</div>}
          <ToastContainer />
          <div className="border-b-2 my-5 py-5">
            <h3 className="heading">New Episode</h3>
            <br />
            <p className="">Upload an audio file or paste a URL</p>
          </div>
          <div className="text-gray-700 form-group md:w-1/2">
            <label className="youtube_url input-label">
              Paste URL (YouTube)
            </label>
            <input
              value={formData.youtubeUrl}
              onChange={e =>
                setFormData({ ...formData, youtubeUrl: e.target.value })
              }
              className="input"
              required
            />
          </div>

          <div className="w-1/4">
            <OR />
          </div>
          <div className="flex items-center space-x-5">
            <div>
              <p className="font-600">
                {uploadedFile
                  ? `Uploaded file: ${uploadedFile}`
                  : "Upload File"}
              </p>
              <p className="text-subtext2">
                Supported formats: mp3, wav. Upload Limit: 100 MB
              </p>
            </div>
            <div>
              <input
                type="file"
                ref={fileInputRef}
                accept=".mp3,.wav"
                className="hidden youtube_file"
                onChange={handleFileUpload}
              />
              <button
                className="border text-white rounded-md py-2 px-3"
                onClick={handleFileSelect}
              >
                <BsUpload size={24} className="text-neutral-700" />
              </button>
            </div>
          </div>
          <div className="border shadow p-5 flex flex-col space-y-3">
            <h3 className="font-600">What do you need? </h3>
            <SwitchButton
              label="Title"
              isLabelVisible={true}
              checked={formData.isTitleNeeded}
              onChange={() =>
                handleSwitchChange("isTitleNeeded", !formData.isTitleNeeded)
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="Transcript"
              isLabelVisible={true}
              checked={formData.isTranscriptNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isTranscriptNeeded",
                  !formData.isTranscriptNeeded
                )
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="Show Notes"
              isLabelVisible={true}
              checked={formData.isDescriptionNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isDescriptionNeeded",
                  !formData.isDescriptionNeeded
                )
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="Timestamps"
              isLabelVisible={true}
              checked={formData.isTimestampNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isTimestampNeeded",
                  !formData.isTimestampNeeded
                )
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="Engaging Snippets"
              isLabelVisible={true}
              checked={formData.isEngagingNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isEngagingNeeded",
                  !formData.isEngagingNeeded
                )
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="Tweet Thread"
              isLabelVisible={true}
              checked={formData.isTweetThreadNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isTweetThreadNeeded",
                  !formData.isTweetThreadNeeded
                )
              }
              isDisabled={"false"}
            />
            <SwitchButton
              label="LinkedIn Post"
              isLabelVisible={true}
              checked={formData.isLinkedPostNeeded}
              onChange={() =>
                handleSwitchChange(
                  "isLinkedPostNeeded",
                  !formData.isLinkedPostNeeded
                )
              }
              isDisabled={"false"}
            />
          </div>

          <div className="space-y-3">
            <h3 className="font-600">Add References</h3>
            <p className="text-sm">
              Generate output in the voice of top podcast creators!
            </p>
            <div className="flex space-x-5 text-subtext2 font-600">
              <div>
                <select
                  value={formData.secondaryReference}
                  required={true}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      secondaryReference: e.target.value
                    })
                  }
                  className="primary_reference py-[0.75] px-1 text-subtext2 font-600 input"
                  style={{ outline: "none" }}
                >
                  <option value="" disabled selected>
                    ✩ Select Reference
                  </option>

                  {secondaryReferences.map((option: any, index) => {
                    if (option.name !== "Yash Bagal") {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="space-y-3 flex justify-between border-b-2 pb-5">
            <div>
              <div className="flex flex-row">
                <h3 className="font-600">I want to be myself!</h3>
              </div>
              <p className="text-subtext2">
                Generate output based on your unique writing style.
              </p>
            </div>

            <SwitchButton
              label="isSelf"
              isLabelVisible={false}
              checked={formData.isSelf}
              onChange={() => {
                handleSwitchChange("isSelf", formData.isSelf);
              }}
              isDisabled={isPersonalized !== "completed" ? "yes" : "no"}
            />
          </div>
          <div className="space-y-3 justify-between border-b-2 pb-7">
            <h3 className="font-600">Add Number of Speakers</h3>
            <p className="text-sm">
              Mention the number of speakers so that Spectral can generate more
              accurate outputs!
            </p>
            <div className="flex space-x-5 text-subtext2 font-600">
              <div>
                <select
                  value={formData.noOfSpeaker}
                  required={false}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      noOfSpeaker: Number(e.target.value)
                    })
                  }
                  className="primary_reference py-[0.75] px-1 text-subtext2 font-600 input"
                  style={{ outline: "none" }}
                >
                  <option value={-1}>Select Number of Speakers</option>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map(number => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="space-y-3 border-b-2 pb-5">
            <h3 className="font-600">Creative Brief</h3>
            <p className="text-sm">
              Adding prompts will help Spectral generate more accurate output.
              Use this like ChatGPT! ;)
            </p>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Vary Sentence Length"
                variant="outlined"
                onClick={() => handleChipClick("Vary Sentence Length")}
              />
              <Chip
                label="Avoid business cliches"
                variant="outlined"
                onClick={() => handleChipClick("Avoid business cliches")}
              />
              <Chip
                label="Avoid thought leadership"
                variant="outlined"
                onClick={() => handleChipClick("Avoid thought leadership")}
              />
              <Chip
                label="Be direct"
                variant="outlined"
                onClick={() => handleChipClick("Be direct")}
              />
              <Chip
                label="Reduce rhetoric flourishes"
                variant="outlined"
                onClick={() => handleChipClick("Reduce rhetoric flourishes")}
              />
              <Chip
                label="No hashtags"
                variant="outlined"
                onClick={() => handleChipClick("No hashtags")}
              />
            </Stack>
            <input
              value={formData.context}
              onChange={e =>
                setFormData({ ...formData, context: e.target.value })
              }
              className="input w-full context"
              placeholder="for eg: vary sentence length, avoid business cliches, etc "
            />
          </div>
          <div className="bg-[#FEFCE8] text-[#714834] md:w-1/2 border-yellow-200 border-2 p-3">
            <div className="flex items-center space-x-2">
              <AiOutlineInfoCircle className="font-bold" />
              <h3 className="font-600">Complete Profile</h3>
            </div>
            <p>
              Connect your accounts [Home{" "}
              <AiOutlineArrowRight className="inline" /> Settings{" "}
              <AiOutlineArrowRight className="inline" /> Profile Completion] for
              Spectral to learn from your existing writing style.
            </p>
          </div>
          <div className="my-10 flex space-x-5 justify-end pb-20">
            <button
              onClick={() => props.router.navigate("/dashboard")}
              className="button text-gray-800 rounded-md"
            >
              &lt; Back
            </button>
            <button
              onClick={handleGenerate}
              className="button generate text-white bg-prim rounded-md"
            >
              &gt; Generate
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-[18px] ${
          sidebarOpen ? " ml-24 sm:ml-30 md:ml-36" : "ml-4"
        } mt-3 md:mt-2 text-black rounded-full p-2`}
        onClick={toggleSidebar}
      >
        {sidebarOpen ? (
          <svg
            className=" w-4 h-4 md:w-6 md:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </div>
    </div>
  );
}

const NewEpisode = withRouter(NewEpisodeWithoutRouter);
export default NewEpisode;

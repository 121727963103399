import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { withRouter } from "../../authentication/withRouter";
import { Link, useNavigate } from "react-router-dom";
import NewProject from "../new_project";
import { newProjectApi } from "../../service/api";
import { ProjectProps } from "../utils/types";
import { ProjectModelDTO } from "../utils/DTO";
import { useSelector } from "react-redux";
import { UserRootState } from "../utils/userSlice";

/**
 * Projects component for displaying a list of user projects and creating new projects.
 *
 * @param {ProjectProps} props - ProjectProps containing user projects.
 * @returns {JSX.Element} Component for managing user projects.
 */
function ProjectsWithoutRouter(props: ProjectProps) {
  const router = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const userDetail = useSelector((state: UserRootState) => state.user);

  const handleCreateProject = async (projectName: string) => {
    // Handle project creation logic here
    const title = projectName;
    const res = await newProjectApi({ title, userId: userDetail.userId ?? "" });
    if (res.error === "Project with the same title already exists") {
      console.log("failed creating project");
    } else {
      if (res.data) router(`/episodes/${res.data.id}`);
    }
  };

  return (
    <div className="mx-auto text-sm">
      <div className="mt-20 md:mt-16 space-y-3">
        <div className="flex text-xl justify-between">
          <h2 className="font-500 text-xl md:text-3xl">Your Projects</h2>
          <button
            onClick={handleOpenModal}
            className="bg-prim text-white flex mr-2 gird items-center space-x-2 rounded py-[6px] px-[15px] text-sm"
          >
            <AiOutlinePlusCircle />
            <span>New</span>
          </button>
          {isModalOpen && (
            <NewProject
              onClose={handleCloseModal}
              onCreate={handleCreateProject}
            />
          )}
        </div>
        <div>
          <p className="text-gray-700 text-xs">
            Create a new project or view existing projects
          </p>
        </div>
      </div>
      <div className="mt-6 md:mt-12">
        <ul>
          {props?.projects.map(
            (project: ProjectModelDTO, key: React.Key | null | undefined) => {
              return (
                <li key={key} className={`border-b-2 py-3`}>
                  <div className="flex space-x-3 justify-between items-center">
                    <div className="flex space-x-3">
                      <div>
                        <p
                          style={{ marginTop: "0.5rem" }}
                          className="text-lg font-600"
                        >
                          {project?.title}
                        </p>
                      </div>
                    </div>

                    <Link to={`/episodes/${project?.id}`}>
                      <div className="pr-2">
                        <button className="bg-prim text-white flex gird items-center space-x-2 rounded py-[4px] px-[12px] text-sm ">
                          <span>View Episodes</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  );
}

const Projects = withRouter(ProjectsWithoutRouter);
export default Projects;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Success Component
 *
 * This component is displayed after a successful payment.
 * It informs the user about the successful payment and redirects them to the dashboard after a countdown.
 */
const Success = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
      if (countdown === 0) {
        navigate("/dashboard");
      }
    };
  }, [navigate, countdown]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-green-200">
      <h1 className="text-3xl font-semibold mb-4">Payment Successful</h1>
      <p className="text-lg">Your payment has been successfully processed.</p>
      <p className="text-lg mt-4">
        You will be redirected to the dashboard in {countdown} seconds.
      </p>
    </div>
  );
};

export default Success;

import React from "react";

function NotFound() {
  return (
    <div className=" text-2xl md:text-4xl text-red-500 h-screen w-screen flex justify-center items-center">
      <p className="font-bold">Page Not Found</p>
    </div>
  );
}

export default NotFound;

import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineStar } from "react-icons/ai";
import { withRouter } from "../../authentication/withRouter";
import { TiTick } from "react-icons/ti";
import { loadStripe } from "@stripe/stripe-js";
import CardModal from "../utils/newCard";
import { fetchCardDetails, stripeCheckoutSession } from "../../service/api";
import {
  BillingPlansList,
  BillingProps,
  CardDetail,
  PropsPlanSchema
} from "../utils/types";

/**
 * BillingsWithoutRouter component for managing billing information, payment methods, and available plans.
 *
 * @param {BillingProps} props - Properties passed to the component.
 * @returns {JSX.Element} Component for managing billing and plans.
 */
function BillingsWithoutRouter(props: BillingProps) {
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetail[]>([]);
  const maxListItems = 8;
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFetchCardDetails = async () => {
    try {
      const response = await fetchCardDetails(props.userId || "");
      if (response.data) {
        setCardDetails(response.data.paymentMethods);
      }
    } catch (error) {
      console.error("Error fetching card details:", error);
    }
  };

  useEffect(() => {
    handleFetchCardDetails();
  }, []);

  const getPlanName = (planId: string): BillingPlansList => {
    switch (planId) {
      case process.env.REACT_APP_FREE_PRODUCT:
        return BillingPlansList.Free;
      case process.env.REACT_APP_CREATOR_PRODUCT:
        return BillingPlansList.Creator;
      case process.env.REACT_APP_PRO_PRODUCT:
        return BillingPlansList.Pro;
      default:
        return BillingPlansList.Default;
    }
  };

  const currentPlanName = getPlanName(props.match?.params.currentPlan || "");

  const makePayment = async (productName: string) => {
    const productId =
      productName === BillingPlansList.Creator
        ? process.env.REACT_APP_CREATOR_PRODUCT
        : productName === BillingPlansList.Pro
        ? process.env.REACT_APP_PRO_PRODUCT
        : "";

    const stripe = await loadStripe(stripePublicKey);

    try {
      const response = await stripeCheckoutSession(productId ?? "");
      const session = response.data;
      let result;
      if (session) {
        result = await stripe?.redirectToCheckout({
          sessionId: session
        });
      }

      if (result?.error) {
        console.error("Error making payment:", result.error);
      }
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };

  return (
    <div className="mx-auto text-sm">
      <div className="mt-20 md:mt-16 space-y-3">
        <h2 className="font-500 text-xl md:text-3xl">Billing</h2>
        <div>
          <p className="text-gray-700 text-xs">
            Manage your billing information and payment methods.
          </p>
        </div>
      </div>
      <br />

      {/*--------------------------------- Payments-------------------------- */}
      <div className="border-b pb-8">
        <h4 className="text-lg font-500 mb-8">Payment methods</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-5">
          {cardDetails.length > 0 ? (
            cardDetails.map((card, index) => (
              <div
                className="p-5 shadow-sm border-2 rounded-md flex flex-col justify-between"
                key={index}
              >
                <div>
                  <div className="flex justify-between">
                    <h5 className="font-600">
                      {card.name} ending in{" "}
                      {card.card_number?.substring(
                        card.card_number?.length - 4
                      )}
                    </h5>
                    {card.isDefault && (
                      <button className="bg-gray-200 rounded px-2 text-xs text-neutral-700">
                        Default
                      </button>
                    )}
                  </div>
                  <p className=" text-subtext2 text-xs">
                    Expires {card.expiry.toString()}
                  </p>
                </div>
                <div className="flex justify-end space-x-4 pt-[10px]">
                  {!card.isDefault && (
                    <button className="flex items-center space-x-1 text-gray-800 font-semibold">
                      <AiOutlineStar /> <span>Set default</span>{" "}
                    </button>
                  )}
                  <button className="flex items-center space-x-2 text-gray-800 font-semibold">
                    <AiOutlineDelete />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No card details available.</p>
          )}
        </div>

        <div>
          <button
            className="text-prim px-4 py-2 font-600 z-0 border-1 text-sm border rounded cursor-pointer"
            onClick={openModal}
          >
            Add payment method
          </button>
          {isModalOpen && <CardModal onClose={closeModal} />}
        </div>
      </div>

      {/*--------------------------------- Plans-------------------------- */}
      <div className="space-y-5 border-b-2 pb-5">
        <h4 className="text-lg font-500 pt-2">Plans</h4>
        <div className="flex">
          <p className="text-neutral-700 text-lg mb-3">Current Plan : </p>
          <p className="text-xl text-prim font-500">
            <b className="ml-2">{currentPlanName}</b>{" "}
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Array.isArray(props.plans) &&
            props.plans.map(
              (plan: PropsPlanSchema, key: React.Key | null | undefined) => {
                const benefitsList = Array(maxListItems)
                  .fill(undefined)
                  .map((_, index) => plan.benefits[index]);

                return (
                  <div
                    className="border shadow flex flex-col px-3 py-5 space-y-3 h-fit rounded-md"
                    key={key}
                  >
                    <div style={{ height: "85%" }}>
                      <div className="flex items-center space-x-3 text-sm">
                        <span className="font-600">{plan.name}</span>
                        {plan.current && (
                          <span className="rounded bg-neutral-100 px-2">
                            Current plan
                          </span>
                        )}
                      </div>
                      <div className="space-x-2">
                        <span className="text-4xl font-600">${plan.price}</span>
                        <span>per month</span>
                      </div>
                      <div className="space-y-3 pt-2">
                        <p className="text-subtext2 text-xs">{plan.text}</p>
                        <ul className="space-y-3">
                          {benefitsList.map((point, i) => (
                            <li className="flex items-center space-x-3" key={i}>
                              {point ? (
                                <>
                                  <TiTick className="text-prim" />{" "}
                                  <span>{point}</span>
                                </>
                              ) : (
                                <span className=" h-5 ">{point}</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <button
                      className={`${
                        plan.name === BillingPlansList.Free ||
                        currentPlanName === plan.name
                          ? "bg-red-500 text-white"
                          : "bg-prim text-white"
                      } button flex items-center justify-center rounded font-600`}
                      style={{ width: "100%" }}
                      onClick={
                        plan.name !== BillingPlansList.Free
                          ? () => makePayment(plan.name)
                          : undefined
                      }
                      disabled={plan.name === currentPlanName}
                    >
                      <TiTick className="" />
                      <span>
                        {plan.name === currentPlanName
                          ? "Current Plan"
                          : plan.name === BillingPlansList.Free
                          ? "Already Included"
                          : currentPlanName === BillingPlansList.Free
                          ? "Select this plan"
                          : "Select this plan"}
                      </span>
                    </button>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}

const Billings = withRouter(BillingsWithoutRouter);
export default Billings;

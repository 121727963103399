import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Represents an individual episode stored in redux.
 */
export interface Episode {
  id: string;
  duplicateId: string;
  title: string;
}

/**
 * Represents the state for episodes being processed.
 */
export interface EpisodeState {
  processingEpisodes: Episode[];
}

const initialState: EpisodeState = {
  processingEpisodes: [] // Episodes that are being processed
};

const episodeSlice = createSlice({
  name: "episode",
  initialState,
  reducers: {
    /**
     * Resets the list of processing episodes to an empty array.
     * @param state - The current EpisodeState.
     */
    resetProcessingEpisodes: state => {
      state.processingEpisodes = [];
    },

    /**
     * Adds a new episode to the list of processing episodes.
     * @param state - The current EpisodeState.
     * @param action - The PayloadAction containing the episode to add.
     */
    addProcessingEpisode: (state, action: PayloadAction<any>) => {
      state.processingEpisodes.push(action.payload);
    },

    /**
     * Updates an existing processing episode in the list.
     * @param state - The current EpisodeState.
     * @param action - The PayloadAction containing the updated episode.
     */
    updateProcessingEpisode: (state, action: PayloadAction<any>) => {
      const updatedIndex = state.processingEpisodes.findIndex(
        ep => ep.id === action.payload.id
      );
      if (updatedIndex !== -1) {
        state.processingEpisodes[updatedIndex] = action.payload;
      }
    },

    /**
     * Removes a processing episode from the list.
     * @param state - The current EpisodeState.
     * @param action - The PayloadAction containing the episode to remove.
     */
    removeProcessingEpisode: (state, action: PayloadAction<any>) => {
      state.processingEpisodes = state.processingEpisodes.filter(
        ep => ep.duplicateId !== action.payload.duplicateId
      );
    }
  }
});

export const {
  resetProcessingEpisodes,
  addProcessingEpisode,
  updateProcessingEpisode,
  removeProcessingEpisode
} = episodeSlice.actions;

/**
 * Represents the root state of the Redux store.
 */
export type RootState = {
  episode: EpisodeState;
};

export default episodeSlice.reducer;

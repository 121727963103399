import React, { useState } from "react";
import { withRouter } from "../../authentication/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { RouterProps } from "react-router-dom";

type NewProject = {
  router: RouterProps;
  onCreate: (projectName: string) => void;
  onClose: () => void;
};

/**
 * Component for creating a new project.
 *
 * @param {NewProject} props - Component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
function NewProjectWithoutRouter(props: NewProject) {
  const [projectName, setProjectName] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onCreate(projectName);
    props.onClose();
  };

  return (
    <div className="fixed p-4 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-75">
      <div className="bg-white px-8 py-4 rounded-md shadow-md w-80">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold ">New Project</h2>
          <button
            className="text-gray-600 hover:text-gray-800 text-lg transition ease-in-out duration-150"
            onClick={props.onClose}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </div>
        <form className="p-4" onSubmit={handleSubmit}>
          <div className="text-gray-700 form-group ">
            <label className="youtube_url input-label">Name</label>
            <input
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
              className="input"
              required
            />
          </div>
          <br />
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="bg-prim text-white flex gird items-center space-x-2 rounded py-[4px] px-[12px] text-sm "
            >
              <span>Create Project</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const NewProject = withRouter(NewProjectWithoutRouter);
export default NewProject;

import { CSSProperties } from "react";
import { To, useLocation, useNavigate, useParams } from "react-router-dom";
import { EpisodeModelDTO, ProjectModelDTO } from "./DTO";

// Interface for a token object
export interface Token {
  access_token: string;
}

// Interface for the response after signing in
export interface SignInResponse {
  success: boolean;
  error?: string;
}

// Interface for login data
export interface LoginData {
  email: string;
  password: string;
}

// Interface for the response after a login attempt
export interface LoginResponse {
  success: boolean;
  error?: string;
}

// Interface for sign-up data
export interface SignUpData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  reference: string;
}

// Interface for the response after signing up
export interface SignUpResponse {
  success: boolean;
  error?: string;
}

export enum BillingPlansList {
  Free = "Free Plan",
  Creator = "Creator Plan",
  Pro = "Pro Plan",
  Default = ""
}

export interface CardDetail {
  name: string;
  card_number: string;
  expiry: Date;
  isDefault: boolean;
  cvv: number;
}

//Interface for User Data Authcontext
export interface UserData {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  cards: CardDetail[];
}

// Interface for new project data
export interface NewProjectData {
  title: string;
  userId: string;
}

export interface User {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  user_id: string;
}

// Interface for new episode data
export interface NewEpisodeData {
  isTitleNeeded: boolean;
  isDescriptionNeeded: boolean;
  isTranscriptNeeded: boolean;
  isTimestampNeeded: boolean;
  isEngagingNeeded: boolean;
  isLinkedPostNeeded: boolean;
  isTweetThreadNeeded: boolean;
  isSelf: boolean;
  youtubeUrl: string;
  youtubeVideo: File | null;
  primaryReference: string;
  secondaryReference: string;
  context: string;
  noOfSpeaker: number;
  userId: string;
}

// Generic ApiResponse interface
export interface ApiResponse<T> {
  success?: boolean;
  data?: T;
  error?: string;
  status?: number;
}

// ProjectModel interface
export interface ProjectModel {
  _doc: ProjectModel | undefined;
  _id: string;
  user_id: string;
  title: string;
  image: string;
  isDeleted: boolean;
  deletedAt: Date | null;
}

// PlanModel interface
export interface PlanModel {
  name: string;
  price: number;
  description: string;
  benefits: string[];
}

export interface PropsProjectSchema {
  title: string;
  id: string;
}

export interface PropsEpisodeSchema {
  image: string | undefined;
  title: string;
  duplicationId: string;
  _id: string;
}

export interface PropsPlanSchema {
  benefits: string[];
  name: string;
  current: string;
  price: string;
  text: string;
}

//Enum for dropdown events
export enum DropdownEvent {
  Duplicate = 1,
  Delete = 2
}

// EpisodeModel interface
export interface EpisodeModel {
  _id: string;
  user_id: string;
  url: string;
  is_title_needed: boolean;
  is_transcript_needed: boolean;
  is_description_needed: boolean;
  is_timestamp_needed: boolean;
  is_engaging_needed: boolean;
  is_episode_completed: boolean;
  is_linked_post_needed: boolean;
  is_tweet_thread_needed: boolean;
  is_self: boolean;
  title: string;
  generated_titles: string;
  description: string;
  timestamp: string[][];
  engaging_snippet: string[];
  tweet_thread: string;
  linkedin_post: string;
  context: string;
  transcript: string;
  reference_type: string;
  project_id: string;
  secondary_reference: string;
  duplicationId: string;

  words: {
    text: string;
    start: number;
    end: number;
    confidence: number;
    speaker: string;
  }[];
  transcriptId: string;
  image: string;
  isDeleted: boolean;
  deletedAt: Date | null;
}

// RouterProps interface
export interface RouterProps {
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
  params: ReturnType<typeof useParams>;
  navigateWithData: (path: To, data: string) => void;
}

// WithAuthenticationProps interface
export interface WithAuthenticationProps {
  router: RouterProps;
}

// BillingProps interface
export type BillingProps = {
  router: RouterProps;
  currentPlan: string;
  userId: string;
  plans: PropsPlanSchema[];
  match: any;
};

// NavbarProps interface
export type NavbarProps = {
  sidebarOpen: boolean;
  props: AllEpisodesProps;
};

// LoadingProps interface
export type LoadingProps = {
  message: string;
};

// AllEpisodesProps interface
export type AllEpisodesProps = {
  router: RouterProps;
  currentPlan: string;
  projects: ProjectModelDTO[];
};

// EpisodeProps interface
export type EpisodeProps = {
  router: RouterProps;
  currentPlan: string;
  projects: ProjectModelDTO[];
  episodes: EpisodeModelDTO[];
  projectNameById: string;
  id: string;
};

// ProjectProps interface
export type ProjectProps = {
  router: RouterProps;
  currentPlan: string;
  projects: ProjectModelDTO[];
};

// AvatarUploaderProps interface
export type AvatarUploaderProps = {
  onFileUpload: (file: File | null) => void;
};

// SettingsFormData interface
export type SettingsFormData = {
  fullName: string;
  email: string;
};

// IntegrationsItem interface
export type IntegrationsItem = {
  id: number;
  title: string;
  subtext: string;
  img: string;
};

// EpisodeFormData interface
export type EpisodeFormData = {
  isTitleNeeded: boolean;
  isDescriptionNeeded: boolean;
  isTranscriptNeeded: boolean;
  isTimestampNeeded: boolean;
  isEngagingNeeded: boolean;
  isLinkedPostNeeded: boolean;
  isTweetThreadNeeded: boolean;
  isSelf: boolean;
  youtubeUrl: string;
  youtubeVideo: File | null;
  primaryReference: string;
  secondaryReference: string;
  context: string;
  noOfSpeaker: number;
};

// RegisterFormData interface
export type RegisterFormData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  reference: string;
};

// CheckmarkProps interface
export interface CheckmarkProps {
  size?: string;
  color?: string;
}

// CustomCSSProperties interface
export interface CustomCSSProperties extends CSSProperties {
  "--checkmark-fill-color"?: string;
}

// DropdownProps interface
export type DropdownProps = {
  id: string;
  fetchData: () => void;
};

// SwitchButtonProps interface
export interface SwitchButtonProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  isLabelVisible: boolean;
  isDisabled?: string;
}

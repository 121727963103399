import { EpisodeModel, ProjectModel } from "./types";

export interface ProjectModelDTO {
  id?: string;
  userId?: string;
  title: string;
  image: string;
  isDeleted: boolean;
  deletedAt: Date | null;
}

export function convertToProjectModelDTO(project: ProjectModel) {
  return {
    doc: project._doc,
    id: project._id,
    userId: project.user_id,
    title: project.title,
    image: project.image,
    isDeleted: project.isDeleted,
    deletedAt: project.deletedAt
  };
}

export interface EpisodeModelDTO {
  id?: string;
  userId?: string;
  url: string;
  isTitleNeeded: boolean;
  isTranscriptNeeded: boolean;
  isDescriptionNeeded: boolean;
  isTimestampNeeded: boolean;
  isEngagingNeeded: boolean;
  isEpisodeCompleted: boolean;
  isLinkedPostNeeded: boolean;
  isTweetThreadNeeded: boolean;
  isSelf: boolean;
  title: string;
  generatedTitles: string;
  description: string;
  timestamp: string[][];
  engagingSnippet: string[];
  tweetThread: string;
  linkedinPost: string;
  context: string;
  transcript: string;
  referenceType: string;
  projectId: string;
  secondaryReference: string;
  duplicationId: string;

  words: {
    text: string;
    start: number;
    end: number;
    confidence: number;
    speaker: string;
  }[];
  transcriptId: string;
  image: string;
  isDeleted: boolean;
  deletedAt: Date | null;
}

export function convertToEpisodeModelDTO(
  episode: EpisodeModel
): EpisodeModelDTO {
  return {
    id: episode._id,
    userId: episode.user_id,
    url: episode.url,
    isTitleNeeded: episode.is_title_needed,
    isTranscriptNeeded: episode.is_transcript_needed,
    isDescriptionNeeded: episode.is_description_needed,
    isTimestampNeeded: episode.is_timestamp_needed,
    isEngagingNeeded: episode.is_engaging_needed,
    isEpisodeCompleted: episode.is_episode_completed,
    isLinkedPostNeeded: episode.is_linked_post_needed,
    isTweetThreadNeeded: episode.is_tweet_thread_needed,
    isSelf: episode.is_self,
    title: episode.title,
    generatedTitles: episode.generated_titles,
    description: episode.description,
    timestamp: episode.timestamp,
    engagingSnippet: episode.engaging_snippet,
    tweetThread: episode.tweet_thread,
    linkedinPost: episode.linkedin_post,
    context: episode.context,
    transcript: episode.transcript,
    referenceType: episode.reference_type,
    projectId: episode.project_id,
    secondaryReference: episode.secondary_reference,
    duplicationId: episode.duplicationId,
    words: episode.words,
    transcriptId: episode.transcriptId,
    image: episode.image,
    isDeleted: episode.isDeleted,
    deletedAt: episode.deletedAt
  };
}

import React, { useState } from "react";
import { SwitchButtonProps } from "./types";

export const disabled = "yes";

/**
 * A toggle switch button component.
 * @param {SwitchButtonProps} props - Props containing label, checked, onChange, isLabelVisible, and isDisabled.
 */
const SwitchButton: React.FC<SwitchButtonProps> = ({
  label,
  checked,
  onChange,
  isLabelVisible,
  isDisabled
}) => {
  const [switchColor, setSwitchColor] = useState(checked ? "prim" : "gray-300");

  const handleToggle = () => {
    const newColor = switchColor === "gray-300" ? "prim" : "gray-300";
    setSwitchColor(newColor);
    onChange();
  };

  return (
    <div className="flex items-center">
      <label htmlFor={label} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            id={label}
            type="checkbox"
            className="sr-only"
            checked={checked}
            onChange={handleToggle}
            disabled={isDisabled === disabled}
          />
          <div
            className={`block bg-${switchColor} w-10 h-6 rounded-full border-2 border-gray-200 bg-gray-200`}
          ></div>
          <div
            className={`dot absolute left-1 top-1   bg-white w-4 h-4 rounded-full transition transform ${
              checked ? "translate-x-4" : ""
            }`}
          ></div>
        </div>
      </label>
      {isLabelVisible && (
        <span className="ml-2 text-xs text-subtext2">{label}</span>
      )}
    </div>
  );
};
export default SwitchButton;

import axios, { AxiosError, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import io from "socket.io-client";
import { removeProcessingEpisode } from "../components/utils/episodeSlice";
import {
  AllEpisodesProps,
  ApiResponse,
  CardDetail,
  EpisodeModel,
  LoginData,
  LoginResponse,
  NewEpisodeData,
  NewProjectData,
  PlanModel,
  ProjectModel,
  SignInResponse,
  SignUpData,
  SignUpResponse,
  Token
} from "../components/utils/types";
import {
  EpisodeModelDTO,
  ProjectModelDTO,
  convertToEpisodeModelDTO,
  convertToProjectModelDTO
} from "../components/utils/DTO";
import { Dispatch } from "@reduxjs/toolkit";
import { setPersonalizationStatus } from "../components/utils/personalizeSlice";

// Create a cookies instance to manage cookies
const cookies = new Cookies();

// Retrieve URL and polling URL from environment variables
const URL = process.env.REACT_APP_URL ?? "";
const polling_URL = process.env.REACT_APP_POLLING_URL ?? "";
// Log URL and polling URL for debugging

/**
 * Sign in with Google using a token.
 * @param {Token} token - The Google access token.
 * @returns {Promise<SignInResponse>} - A promise that resolves with the sign-in response.
 */
export async function GoogleSignIn(token: Token): Promise<SignInResponse> {
  try {
    // Send a POST request to the Google sign-in route
    const res: AxiosResponse<{ token: string }> = await axios.post(
      `${URL}/user/googleLogin`,
      {
        token: token.access_token
      }
    );

    // Retrieve the JWT token from the response
    const jwtToken: string = res.data.token;

    // Set the JWT token in cookies
    cookies.set("token", jwtToken, { path: "/" });

    return { success: true };
  } catch (error) {
    // Handle errors
    if (axios.isAxiosError(error) && error.response) {
      return { success: false, error: error.response.data.error };
    } else {
      return {
        success: false,
        error: "Error occurred while sending the request"
      };
    }
  }
}

/**
 * Get user information by decoding the JWT token stored in cookies.
 * @returns {undefined} - User information or undefined if the token is invalid or not found.
 */
export async function getUserByToken() {
  const token = cookies.get("token");
  try {
    // Decode the JWT token to retrieve user information
    const user = jwt_decode(token);
    return user;
  } catch (err) {
    return undefined;
  }
}

/**
 * Login using the provided credentials.
 * @param {LoginData} data - The user's login credentials.
 * @returns {Promise<LoginResponse>} - A promise that resolves with the login response.
 */
export async function login(data: LoginData): Promise<LoginResponse> {
  try {
    // Send a POST request to the login route with user credentials
    const res: AxiosResponse<{ token: string }> = await axios.post(
      `${URL}/user/login`,
      {
        ...data
      }
    );

    // Retrieve the JWT token from the response
    const token: string = res.data.token;

    // Set the JWT token in cookies
    cookies.set("token", token, { path: "/" });

    return { success: true };
  } catch (error) {
    // Handle errors
    if (axios.isAxiosError(error) && error.response) {
      return { success: false, error: error.response.data.error };
    } else {
      return {
        success: false,
        error: "Error occurred while sending the request"
      };
    }
  }
}

/**
 * Sign up with the provided user data.
 * @param {SignUpData} data - The user's sign-up data.
 * @returns {Promise<SignUpResponse>} - A promise that resolves with the sign-up response.
 */
export async function signUp(data: SignUpData): Promise<SignUpResponse> {
  try {
    // Send a POST request to the registration route with user data
    const res: AxiosResponse<{ token: string }> = await axios.post(
      `${URL}/user/register`,
      {
        ...data
      }
    );

    // Retrieve the JWT token from the response
    const token: string = res.data.token;

    // Set the JWT token in cookies
    cookies.set("token", token, { path: "/" });

    return { success: true };
  } catch (error) {
    // Handle errors
    if (axios.isAxiosError(error) && error.response) {
      return { success: false, error: error.response.data.error };
    } else {
      return {
        success: false,
        error: "Error occurred while sending the request"
      };
    }
  }
}

/**
 * Create a new project via API.
 * @param {NewProjectData} data - Data for creating a new project.
 * @returns {Promise<ApiResponse<ProjectModelDTO>>} - A promise that resolves with the API response.
 */
export async function newProjectApi(
  data: NewProjectData
): Promise<ApiResponse<ProjectModelDTO>> {
  try {
    // Send a POST request to create a new project
    const res: AxiosResponse = await axios.post(`${URL}/project/create`, {
      ...data
    });
    return {
      success: true,
      data: convertToProjectModelDTO(res.data.project._doc)
    };
  } catch (error) {
    // Handle errors
    if (axios.isAxiosError(error) && error.response) {
      return { success: false, error: error.response.data.message };
    } else {
      return {
        success: false,
        error: "Error occurred while sending the request"
      };
    }
  }
}

/**
 * Handle creating a new episode via API with progress messages.
 * @param {NewEpisodeData} data - Data for creating a new episode.
 * @param {function} setLoading - Function to set loading state.
 * @param {function} setProject - Function to set project state.
 * @param {function} setMessage - Function to set progress message state.
 * @param {AllEpisodesProps} props - Component props.
 * @param {string} project_id - The ID of the project.
 * @param {Dispatch} dispatch - Function to dispatch actions.
 * @returns {Promise<ApiResponse<EpisodeModel>>} - A promise that resolves with the API response.
 */
export async function newEpisodeHandlerAPI(
  data: NewEpisodeData,
  setLoading: (loading: boolean) => void,
  setEpisode: (episode: EpisodeModelDTO) => void,
  setMessage: (message: string) => void,
  props: AllEpisodesProps,
  project_id: string,
  dispatch: Dispatch
): Promise<ApiResponse<EpisodeModelDTO>> {
  try {
    // Send a PUT request to create a new episode with multipart/form-data
    const response: AxiosResponse = await axios.put(
      `${URL}/episode/createAPI/${project_id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    setLoading(true);

    // Wait for 3 seconds
    await new Promise<void>(resolve => {
      setTimeout(resolve, 3000);
    });

    setEpisode(convertToEpisodeModelDTO(response.data));

    // Use Promises and async/await for sequential messaging
    await new Promise<void>(resolve => {
      setMessage("Brewing a cup of coffee");
      setTimeout(resolve, 3000);
    });

    await new Promise<void>(resolve => {
      setMessage("Procrastinating");
      setTimeout(resolve, 3000);
    });

    await new Promise<void>(resolve => {
      setMessage("checkmark");
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 5000);
    });

    // Listen for status changes via WebSocket
    listenStatusChange(dispatch);

    return {
      success: true,
      data: convertToEpisodeModelDTO(response.data.episode._doc)
    };
  } catch (error) {
    return { success: false, error: `${error}` };
  }
}

/**
 * Listen for status changes via WebSocket.
 * @param {Dispatch} dispatch - Function to dispatch actions.
 */
export async function listenStatusChange(dispatch: Dispatch) {
  const socket = io(polling_URL, { secure: true });
  try {
    socket.on("connect", () => {
      console.log(
        "Socket Initiated Listening changes.....",
        socket.id,
        socket.io.opts.port
      );
    });

    socket.on("processComplete", response => {
      console.log("Episode Process Complete: ", response);
      const payload = {
        status: "completed",
        duplicationId: response.duplicationId
      };
      dispatch(removeProcessingEpisode(payload));
    });

    socket.on("disconnect", () => {
      console.log("WebSocket connection closed.");
      // Close the WebSocket connection
      socket.close();
    });
  } catch (error) {
    if (socket && socket.connected) {
      socket.close();
    }
  }
}

/**
 * Get projects of a user via API.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<ApiResponse<{ success: boolean, data: ProjectModel[]  }>>} - A promise that resolves with the API response.
 */
export async function getProjectsOfUserAPI(
  userId: string
): Promise<ApiResponse<{ success: boolean; data: ProjectModel[] }>> {
  try {
    const res: AxiosResponse = await axios.get(`${URL}/project`, {
      params: { user_id: userId }
    });
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Get the current plan of a user via API.
 * @param {string} user_id - The ID of the user.
 * @returns {Promise<ApiResponse<string>>} - A promise that resolves with the API response.
 */
export async function getCurrentPlan(
  user_id: string
): Promise<ApiResponse<string>> {
  try {
    const res: AxiosResponse = await axios.get(
      `${URL}/user/getCurrentPlan/${user_id}`
    );
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Duplicate an episode via API.
 * @param {string} id - The ID of the episode to duplicate.
 * @returns {Promise<ApiResponse<EpisodeModel>>} - A promise that resolves with the API response.
 */
export async function duplicateEpisode(
  id: string
): Promise<ApiResponse<EpisodeModel>> {
  try {
    const res: AxiosResponse = await axios.put(
      `${URL}/episode/duplicateEpisode/${id}`
    );
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}
/**
 * Add a new card via API.
 * @param {Object} payload - Card data including card_number, expiry, name, cvv, isDefault, and userID.
 * @returns {Promise<ApiResponse<{ name: string; card_number: string; expiry: string; isDefault: boolean; cvv: number }>>} - A promise that resolves with the API response.
 */
export async function addCard(payload: {
  card_number: string;
  expiry: string;
  name: string;
  cvv: string;
  isDefault: boolean;
  userID: string;
}): Promise<
  ApiResponse<{
    name: string;
    card_number: string;
    expiry: string;
    isDefault: boolean;
    cvv: number;
  }>
> {
  try {
    const res: AxiosResponse = await axios.post(`${URL}/user/addCard`, {
      ...payload
    });
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Delete an episode via API.
 * @param {string} id - The ID of the episode to delete.
 * @returns {Promise<ApiResponse<EpisodeModel>>} - A promise that resolves with the API response.
 */
export async function deleteEpisode(
  id: string
): Promise<ApiResponse<EpisodeModel>> {
  try {
    const res: AxiosResponse = await axios.get(`${URL}/episode/delete/${id}`);
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Delete a user account via API.
 * @param {string} id - The ID of the user account to delete.
 * @returns {Promise<ApiResponse<{ message?: string; error?: string }>>} - A promise that resolves with the API response.
 */
export async function deleteUserAccount(
  id: string
): Promise<ApiResponse<{ message?: string; error?: string }>> {
  try {
    const res: AxiosResponse = await axios.get(
      `${URL}/user/deleteUserAccount/${id}`
    );
    return res;
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Update user data via API.
 * @param {Object} data - User data including userId, fullName, and email.
 * @returns {Promise<ApiResponse<string>>} - A promise that resolves with the API response.
 */
export async function updateUserData(data: {
  userId: string;
  fullName: string;
  email: string;
}): Promise<ApiResponse<string>> {
  try {
    const res: AxiosResponse = await axios.post(
      `${URL}/user/updateUserDetails`,
      data
    );
    return res;
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Get episodes of a project via API.
 * @param {string} id - The ID of the project.
 * @returns {Promise<ApiResponse<{ episode: EpisodeModel[]; project: ProjectModel }>>} - A promise that resolves with the API response.
 */
export async function getEpisodesOfProjectsAPI(
  id: string
): Promise<ApiResponse<{ episode: EpisodeModel[]; project: ProjectModel }>> {
  try {
    const res: AxiosResponse = await axios.patch(`${URL}/episode/${id}`);
    return res.data;
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Fetch card details via API.
 * @param {string} id - The ID of the card to fetch details for.
 * @returns {Promise<ApiResponse<{ paymentMethods: CardDetail[] }>>} - A promise that resolves with the API response.
 */
export async function fetchCardDetails(
  id: string
): Promise<ApiResponse<{ paymentMethods: CardDetail[] }>> {
  try {
    const res: AxiosResponse = await axios.get(
      `${URL}/user/getCardDetails/${id}`
    );
    return res;
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Create a Stripe checkout session via API.
 * @param {string} data - Product ID for the checkout session.
 * @returns {Promise<ApiResponse<string>>} - A promise that resolves with the API response.
 */
export async function stripeCheckoutSession(
  data: string
): Promise<ApiResponse<string>> {
  try {
    const res = await axios.post(`${URL}/plan/payment-checkout`, {
      product_id: data
    });
    return { success: true, data: res.data };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Get all plans via API.
 * @returns {Promise<ApiResponse<PlanModel[]>>} - A promise that resolves with the API response.
 */
export async function getAllPlansAPI(): Promise<ApiResponse<PlanModel[]>> {
  try {
    const res = await axios.get(`${URL}/plan`);
    return { success: true, data: res.data.data };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Get all episodes of a project via API.
 * @param {string} id - The ID of the project.
 * @returns {Promise<ApiResponse<EpisodeModel>>} - A promise that resolves with the API response.
 */
export async function getAllEpisodes(
  id: string
): Promise<ApiResponse<EpisodeModel>> {
  try {
    const res = await axios.get(`${URL}/episode/${id}`);
    return { success: true, data: res.data };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while getting all episodes"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while getting all episodes"
  };
}

/**
 * Check if episode upload is possible via API.
 * @param {string} id - The ID of the episode to check.
 * @returns {Promise<ApiResponse<boolean>>} - A promise that resolves with the API response.
 */
export async function checkEpisodeUpload(
  id: string
): Promise<ApiResponse<boolean>> {
  try {
    const res = await axios.get(`${URL}/episode/checkEpisodeUpload/${id}`);
    return { success: true, data: res.data.canUpload };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while checking episode upload"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while checking episode upload"
  };
}

/**
 * Edit episode title via API.
 * @param {Object} dataTitle - The data containing episode ID and new title.
 * @param {string} dataTitle.id - The ID of the episode.
 * @param {string} dataTitle.title - The new episode title.
 * @returns {Promise<ApiResponse<null>>} - A promise that resolves with the API response.
 */
export async function editEpisodeTitle(dataTitle: {
  id: string;
  title: string;
}): Promise<ApiResponse<null>> {
  const data = { title: dataTitle.title };
  try {
    await axios.put(`${URL}/episode/updateEpisode/${dataTitle.id}`, data);
    return { success: true };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return { success: false, error: "Error occurred while editing title" };
      }
  }
  return { success: false, error: "Error occurred while editing title" };
}

/**
 * Edit episode fields via API.
 * @param {string} fieldName - The name of the field to edit.
 * @param {string} value - The new value for the field.
 * @param {string} id - The ID of the episode.
 * @returns {Promise<ApiResponse<null>>} - A promise that resolves with the API response.
 */
export async function editEpisodeFields(
  fieldName: string,
  value: string,
  id: string
): Promise<ApiResponse<null>> {
  let data;
  if (fieldName === "titles") {
    data = { generated_titles: value };
  } else if (fieldName === "description") {
    data = { description: value };
  } else if (fieldName === "timestamps") {
    data = { timestamp: value };
  } else if (fieldName === "engagingSnippets") {
    data = { engaging_snippet: value };
  } else if (fieldName === "linkedinPost") {
    data = { linkedin_post: value };
  } else if (fieldName === "tweetThread") {
    data = { tweet_thread: value };
  }
  try {
    await axios.put(`${URL}/episode/updateEpisode/${id}`, data);
    return { success: true };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return { success: false, error: "Error occurred while editing field" };
      }
  }
  return { success: false, error: "Error occurred while editing field" };
}

/**
 * Get all secondary references via API.
 * @returns {Promise<ApiResponse<{ name: string; rss_writing_style: string; twitter_writing_style: string; linkedin_writing_style: string; }[]>>} - A promise that resolves with the API response.
 */
export async function getAllSecondaryRef(): Promise<
  ApiResponse<
    {
      name: string;
      rss_writing_style: string;
      twitter_writing_style: string;
      linkedin_writing_style: string;
    }[]
  >
> {
  try {
    const res = await axios.get(`${URL}/secondary_reference`);
    return { success: true, data: res.data.data };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while getting secondary references"
        };
      }
    }
    return {
      success: false,
      error: "Error occurred while getting secondary references"
    };
  }
}

/**
 * Handles API errors and returns a standard response object.
 * @param {unknown} err - The error object from Axios.
 * @returns {ApiResponse} - A standardized API response.
 */
function handleErrors(err: unknown): ApiResponse<undefined> {
  if (err instanceof AxiosError) {
    if (err.response) {
      return { success: false, error: err.response.data.error };
    } else {
      return {
        success: false,
        error: "Error occurred while sending the request"
      };
    }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

/**
 * Get an episode by ID via API.
 * @param {string} episodeId - The ID of the episode to fetch.
 * @returns {Promise<ApiResponse<{ episode: EpisodeModel; fields: { titles: string; description: string; timestamps: string; tweetThread: string; linkedinPost: string; engagingSnippets: string; }; }>>} - A promise that resolves with the API response.
 */
export async function getEpisodeById(episodeId: string): Promise<
  ApiResponse<
    | {
        episode: EpisodeModel;
        fields: {
          titles: string;
          description: string;
          timestamps: string;
          tweetThread: string;
          linkedinPost: string;
          engagingSnippets: string;
        };
      }
    | undefined
  >
> {
  try {
    const response = await axios.get(`${URL}/episode/${episodeId}`);
    const episode = response.data.data;
    const fields = {
      titles: episode.generated_titles || "",
      description: episode.description || "",
      timestamps: episode.timestamp || "",
      tweetThread: episode.tweet_thread || "",
      linkedinPost: episode.linkedin_post || "",
      engagingSnippets: episode.engaging_snippet || ""
    };

    return { success: true, data: { episode, fields } };
  } catch (error) {
    return handleErrors(error);
  }
}

export async function personalizationHandlerAPI(
  dispatch: Dispatch,
  userId: string
): Promise<ApiResponse<any>> {
  try {
    // Send a PUT request to create a new episode with multipart/form-data
    const response: AxiosResponse = await axios.post(
      `${URL}/user/personaliztion/${userId}`
    );
    if (response.status === 200) {
      dispatch(setPersonalizationStatus("in_progress"));
      // Listen for status changes via WebSocket
      listenPersonalizationStatus(dispatch);
    }

    return {
      success: true,
      data: "Personlization Started"
    };
  } catch (error) {
    return { success: false, error: `${error}` };
  }
}

/**
 * Listen for status changes via WebSocket.
 * @param {Dispatch} dispatch - Function to dispatch actions.
 */
export async function listenPersonalizationStatus(dispatch: Dispatch) {
  const socket = io(polling_URL, { secure: true });
  try {
    socket.on("connect", () => {
      console.log(
        "Socket Initiated Listening changes.....",
        socket.id,
        socket.io.opts.port
      );
    });

    socket.on("processComplete", response => {
      console.log("Personalization Process Complete: ", response);
      dispatch(setPersonalizationStatus("completed"));
    });

    socket.on("disconnect", () => {
      console.log("WebSocket connection closed.");
      // Close the WebSocket connection
      socket.close();
    });
  } catch (error) {
    if (socket && socket.connected) {
      socket.close();
    }
  }
}

export async function checkPersonalizationStatus(
  id: string
): Promise<ApiResponse<boolean>> {
  try {
    const res = await axios.get(`${URL}/user/checkPersonalizationStatus/${id}`);
    return { success: true, data: res.data.canUpload };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while checking personalization status"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while checking episode upload"
  };
}

export async function getUserDetailsAPI(
  emailId: string
): Promise<ApiResponse<any>> {
  try {
    const res = await axios.post(`${URL}/user/getUserByEmailId/`, {
      emailId: emailId
    });
    return { success: true, data: res.data.data };
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

export const initiateTwitterOAuth = async (email: string) => {
  try {
    const response = await axios.post(`${URL}/user/twitter`, { email });
    window.location.href = response.data.authUrl;
  } catch (error) {
    // Handle errors
  }
};

export async function updateSocialMediaUsername(
  data: any
): Promise<ApiResponse<string>> {
  try {
    await axios.post(`${URL}/user/updateSocials`, data);
  } catch (error) {
    if (error instanceof AxiosError)
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else {
        return {
          success: false,
          error: "Error occurred while sending the request"
        };
      }
  }
  return {
    success: false,
    error: "Error occurred while sending the request"
  };
}

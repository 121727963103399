import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Failed component displayed when a payment fails, with a countdown timer to redirect to the dashboard.
 *
 * @returns {JSX.Element} Component for displaying a payment failure message.
 */
const Failed = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
      if (countdown === 0) {
        navigate("/dashboard");
      }
    };
  }, [navigate, countdown]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-red-200">
      <h1 className="text-3xl font-semibold mb-4">Payment Failed</h1>
      <p className="text-lg">Unfortunately, your payment was not successful.</p>
      <p className="text-lg mt-4">
        You will be redirected to the dashboard in {countdown} seconds.
      </p>
    </div>
  );
};

export default Failed;

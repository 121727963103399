// userSlice.ts
import { createSlice } from "@reduxjs/toolkit";

// Define the state for the user slice
export interface UserState {
  name: string;
  userId: string;
}

const initialState: UserState = {
  name: "",
  userId: ""
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // Action payload should contain user data with name and user_id
      state.name = action.payload.name;
      state.userId = action.payload.userId;
    }
  }
});

export type UserRootState = {
  user: UserState;
};

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

import React, { ComponentType } from "react";
import { To, useLocation, useNavigate, useParams } from "react-router-dom";
import { RouterProps } from "../components/utils/types";

/**
 * withRouter Higher-Order Component (HOC)
 *
 * This HOC injects router-related props into a wrapped component, allowing it to access routing functionality.
 *
 * @param Component - The component to be wrapped with router props.
 * @returns A new component with router props injected.
 */
export function withRouter<CustomProps>(
  Component: ComponentType<{ router: RouterProps } & CustomProps>
) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const navigateWithData = (path: To, data: string) => {
      navigate(path, { state: data });
    };
    return (
      <Component
        {...props}
        router={{ location, navigate, params, navigateWithData }}
      />
    );
  }

  return ComponentWithRouterProp;
}
export type { RouterProps };

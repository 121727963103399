import { createSlice } from "@reduxjs/toolkit";

// Define the possible states for personalization
enum PersonalizationStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed"
}

/**
 * Represents the state for personalization.
 */
export interface PersonalizeState {
  status: PersonalizationStatus;
}

const initialState: PersonalizeState = {
  status: PersonalizationStatus.NOT_STARTED
};

const personalizeSlice = createSlice({
  name: "personalize",
  initialState,
  reducers: {
    setPersonalizationStatus: (state, action) => {
      state.status = action.payload;
    }
  }
});

export type PersonalizeRootState = {
  personalize: PersonalizeState;
};

export const { setPersonalizationStatus } = personalizeSlice.actions;

export default personalizeSlice.reducer;

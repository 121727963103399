import React, { createContext, useContext, useReducer, ReactNode } from "react";

// Interface representing an episode
interface Episode {
  id: string;
  duplicateId: string;
}

// Interface representing the state of episodes
interface EpisodeState {
  processingEpisodes: Episode[];
}

// Interface representing an episode-related action
interface EpisodeAction {
  type: string;
  payload: Episode;
}

// Create a context for episode-related state management
const EpisodeContext = createContext<
  { state: EpisodeState; dispatch: React.Dispatch<EpisodeAction> } | undefined
>(undefined);

// Initial state for episode-related data
const initialState: EpisodeState = {
  processingEpisodes: [] // Episodes that are being processed
};

// Reducer function to handle episode-related actions
const reducer = (state: EpisodeState, action: EpisodeAction): EpisodeState => {
  switch (action.type) {
    case "RESET_PROCESSING_EPISODES":
      return {
        ...state,
        processingEpisodes: []
      };
    case "ADD_PROCESSING_EPISODE":
      return {
        ...state,
        processingEpisodes: [...state.processingEpisodes, action.payload]
      };
    case "UPDATE_PROCESSING_EPISODE":
      return {
        ...state,
        processingEpisodes: state.processingEpisodes.map(episode =>
          episode.id === action.payload.id ? action.payload : episode
        )
      };
    case "REMOVE_PROCESSING_EPISODE":
      return {
        ...state,
        processingEpisodes: state.processingEpisodes.filter(
          episode => episode.id !== action.payload.duplicateId
        )
      };
    default:
      return state;
  }
};

interface EpisodeProviderProps {
  children: ReactNode;
}

// EpisodeProvider component to provide episode-related state to children
const EpisodeProvider: React.FC<EpisodeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EpisodeContext.Provider value={{ state, dispatch }}>
      {children}
    </EpisodeContext.Provider>
  );
};

const useEpisodeContext = () => {
  const context = useContext(EpisodeContext);
  if (context === undefined) {
    throw new Error("useEpisodeContext must be used within an EpisodeProvider");
  }
  return context;
};

export { EpisodeProvider, useEpisodeContext };

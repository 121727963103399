import React from "react";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NotFound from "./components/notFound/not_found";
import Dashboard from "./components/dashboard";
import NewProject from "./components/new_project";
import Generate from "./components/generate";
import NewEpisode from "./components/new_episode";
import AllEpisodes from "./components/episodes";
import GeneratedContent from "./components/view-content";
import { EpisodeProvider } from "./components/utils/EpisodeContext";
import SuccessPage from "./components/dashboard/SuccessPage";
import FailedPage from "./components/dashboard/ErrorPage";
import { withAuthenticationRequired } from "@auth0/auth0-react";

/**
 * The root component of the React application.
 *
 * @param {object} props - Additional properties passed to the component.
 * @returns {JSX.Element} - The rendered application component.
 */

function App() {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";

  return (
    <>
      <GoogleOAuthProvider clientId={googleClientId}>
        <EpisodeProvider>
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/*" element={<NotFound />} />
            <Route path="/generate" element={<Generate />} />
            <Route path="/episodes/:id" element={<AllEpisodes />} />
            <Route path="/new-project" element={<NewProject />} />
            <Route path="/new-episode/:id" element={<NewEpisode />} />
            <Route path="/view-content/:id" element={<GeneratedContent />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/cancel" element={<FailedPage />} />
            <Route path="/dashboard" element={<Dashboard />}></Route>
          </Routes>
        </EpisodeProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default withAuthenticationRequired(App);

import React from "react";

const OR = () => {
  return (
    <div className={`relative `}>
      <div className="border-t border-black"></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4">
        OR
      </div>
    </div>
  );
};

export default OR;

import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "../../authentication/withRouter";
import { BsClipboard } from "react-icons/bs";
import { Navbar } from "../dashboard/navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllEpisodesProps } from "../utils/types";

/**
 * GenerateWithoutRouter component for generating content with copy functionality.
 *
 * @param {AllEpisodesProps} props - Properties passed to the component.
 * @returns {JSX.Element} Generated content with copy functionality.
 */
function GenerateWithoutRouter(props: AllEpisodesProps) {
  const showToastMessage = () => {
    toast.success("Text Copied !", {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const data = props?.router?.location?.state;

  const [fields, setFields] = useState({
    title: data?.titles || "",
    description: data?.descriptions || "",
    engaging_snippets: data?.engaging_snippets || "",
    timestamps: data?.timestamps || "",
    tweetThread: data?.twitter || "",
    linkedInPost: data?.linkedin || ""
  });

  const handleCopy = () => {
    showToastMessage();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFields(prevFields => ({ ...prevFields, [name]: value }));
  };
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className="flex h-screen text-sm">
      {sidebarOpen && <Navbar sidebarOpen={sidebarOpen} props={props} />}
      {/* Main Area */}
      <div
        className={`flex-grow px-2 md:px-6 pt-3 ${
          sidebarOpen ? "ml-32 md:ml-48" : "ml-8"
        }`}
      >
        <ToastContainer autoClose={1000} hideProgressBar />
        <div className="w-[95%] mx-auto space-y-10 my-5 text-sm">
          <div className="border-b-2 my-5 py-8">
            <h3 className="heading">{data?.title} </h3>zz
            <br />
            {data?.transcript && (
              <p className="mt-10 text-subtext">{data?.transcript}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="title" className="input-label">
              Episode Titles
            </label>
            <div className="flex space-x-3">
              <textarea
                className="input w-full md:w-1/2 h-20"
                id="title"
                name="title"
                value={fields.title}
                onChange={handleInputChange}
              />
              <CopyToClipboard text={fields.title} onCopy={handleCopy}>
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description" className="input-label">
              Episode Description
            </label>
            <div className="flex space-x-3">
              <textarea
                className="input w-full md:w-1/2 input h-20"
                id="description"
                name="description"
                value={fields.description}
                onChange={handleInputChange}
              />
              <CopyToClipboard text={fields.description} onCopy={handleCopy}>
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="timestamps" className="input-label">
              Timestamps
            </label>
            <div className="flex space-x-3">
              <textarea
                className="input w-full md:w-1/2 input h-20"
                id="timestamps"
                name="timestamps"
                value={fields.timestamps}
                onChange={handleInputChange}
              />
              <CopyToClipboard text={fields.timestamps} onCopy={handleCopy}>
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="tweetThread" className="input-label">
              Tweet Thread
            </label>
            <div className="flex space-x-3">
              <textarea
                className="input w-full md:w-1/2 input h-20"
                id="tweetThread"
                name="tweetThread"
                value={fields.tweetThread}
                onChange={handleInputChange}
              />
              <CopyToClipboard text={fields.tweetThread} onCopy={handleCopy}>
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="description" className="input-label">
              Engaging Snippets
            </label>
            <div className="flex space-x-3">
              <textarea
                className="input w-full md:w-1/2 input h-20"
                id="description"
                name="description"
                value={fields.engaging_snippets}
                onChange={handleInputChange}
              />
              <CopyToClipboard
                text={fields.engaging_snippets}
                onCopy={handleCopy}
              >
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="linkedinPost" className="input-label">
              LinkedIn Post
            </label>
            <div className="flex space-x-3 pb-10">
              <textarea
                className="input w-full md:w-1/2 input h-20"
                id="linkedinPost"
                name="linkedinPost"
                value={fields.linkedInPost}
                onChange={handleInputChange}
              />
              <CopyToClipboard text={fields.linkedInPost} onCopy={handleCopy}>
                <div className="text-subtext" style={{ marginTop: "5px" }}>
                  <BsClipboard />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-[18px] ${
          sidebarOpen ? " ml-24 sm:ml-30 md:ml-36" : "ml-4"
        } mt-3 md:mt-2 text-black rounded-full p-2`}
        onClick={toggleSidebar}
      >
        {sidebarOpen ? (
          <svg
            className=" w-4 h-4 md:w-6 md:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </div>
    </div>
  );
}

const Generate = withRouter(GenerateWithoutRouter);
export default Generate;

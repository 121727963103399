import { faCopy, faEllipsis, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { deleteEpisode, duplicateEpisode } from "../../service/api";
import { DropdownEvent, DropdownProps } from "./types";

/**
 * DropdownComponent displays a dropdown menu with options like Duplicate and Delete.
 *
 * @param {DropdownProps} props - Component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
function DropdownComponent(props: DropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  /**
   * Handles the click event on the dropdown button.
   *
   * @param {number} value - The selected dropdown option (1 for Duplicate, 2 for Delete).
   */
  const handleDropdownClick = async (value: number) => {
    if (value === DropdownEvent.Duplicate) {
      try {
        await duplicateEpisode(props.id);
      } catch (error) {
        console.log(" Duplicate Failed", error);
      }
    } else if (value === DropdownEvent.Delete) {
      try {
        await deleteEpisode(props.id);
      } catch (error) {
        console.log("Delete Failed", error);
      }
    }
    setIsDropdownOpen(false);
    props.fetchData();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: { target: any }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      window.addEventListener("mousedown", handleOutsideClick);
    } else {
      window.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="bg-white hover:bg-border-[#7C3AED] font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <FontAwesomeIcon icon={faEllipsis} />
      </button>
      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div
          id="dropdown"
          className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute top-10 right-0"
        >
          <ul
            className="py-2 text-sm text-gray-700"
            aria-labelledby="dropdownDefaultButton"
          >
            <li className="p-2">
              <button
                onClick={() => {
                  handleDropdownClick(1);
                }}
              >
                <FontAwesomeIcon icon={faCopy} className="ml-2" /> Duplicate
              </button>
            </li>
            <li className="p-2">
              <button
                onClick={() => {
                  handleDropdownClick(2);
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="ml-2" /> Delete
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownComponent;

import React, { useEffect, useState, ChangeEvent } from "react";
import { AiFillDelete } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import Twitter from "../../../assets/twitter.png";
import Spotify from "../../../assets/spotify.png";
import Substack from "../../../assets/substack.png";
import Transistor from "../../../assets/transistor.jpg";
import { BiEdit } from "react-icons/bi";
import Cookies from "js-cookie";
import {
  deleteUserAccount,
  initiateTwitterOAuth,
  updateSocialMediaUsername,
  updateUserData
} from "../../../service/api";
import { withRouter, RouterProps } from "../../../authentication/withRouter";
import { IntegrationsItem, SettingsFormData, User } from "../../utils/types";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { UserRootState } from "../../utils/userSlice";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SettingsProps = RouterProps;

/**
 * SettingsWithoutRouter component for managing user settings and account deletion.
 *
 * @param {SettingsProps} props - Properties passed to the component.
 * @returns {JSX.Element} Component for managing user settings and account deletion.
 */
function SettingsWithoutRouter(props: SettingsProps) {
  const { user } = useAuth0<User>();
  const _location = props.location;
  const [_uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<SettingsFormData>({
    fullName: "",
    email: ""
  });
  const [editable, setEditable] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [socialHandle, setSocialHandle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [usernamePlaceholder, setUsernamePlaceholder] = useState("");

  const openModal = (title: string) => {
    setModalTitle(title);
    setUsernamePlaceholder(`Enter your ${title} username`);
    setShowConnectModal(true);
  };
  const closeModal = () => {
    setShowConnectModal(false);
  };

  const handleConnect = async (e: any) => {
    e.preventDefault();
    try {
      const payload = {
        username: socialHandle,
        type: modalTitle,
        userId: userDetail.userId ?? ""
      };
      const res = await updateSocialMediaUsername(payload);
      if (res.status === 200) {
        // Handle success
      }
    } catch (error) {
      // Handle error
    }
    closeModal();
  };
  const userDetail = useSelector((state: UserRootState) => state.user);

  useEffect(() => {
    setFormData({
      ...formData,
      fullName: `${user?.given_name} ${user?.family_name}`,
      email: user?.email || ""
    });
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const _handleTwitterConnect = async () => {
    try {
      await initiateTwitterOAuth(user?.email ?? "");
    } catch (error) {
      // Handle errors
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = { ...formData, userId: userDetail.userId ?? "" };
      const res = await updateUserData(payload);
      if (res.status === 200) {
        // Handle success
      }
    } catch (error) {
      // Handle error
    }
  };

  const _handleFileUpload = (file: File | null) => {
    setUploadedFile(file);
  };

  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleDeleteConfirmed = async () => {
    try {
      const res = await deleteUserAccount(userDetail.userId ?? "");
      if (res.status === 200) {
        Cookies.remove("token");
        window.location.reload();
      }
    } catch (error) {
      // Handle error
    }
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="mx-auto text-sm">
      <div className="mt-20 md:mt-16 space-y-3">
        <h2 className="font-500 text-xl md:text-3xl">Your Account</h2>
        <div>
          <p className="text-gray-700 text-xs">
            Update your profile and personal details here.
          </p>
        </div>
      </div>
      <br />
      <form className="space-y-5">
        <div className="mb-4">
          <label htmlFor="fullName" className="block mb-2 input-label">
            Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className={`w-full md:w-1/2 input ${
              editable ? "" : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!editable}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 input-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            disabled={!editable}
            onChange={handleChange}
            className={`w-full md:w-1/2 input ${
              editable ? "" : "opacity-50 cursor-not-allowed"
            }`}
            required
          />
        </div>
        {/* <AvatarUploader onFileUpload={handleFileUpload} /> */}
        <div className="flex space-x-4">
          {editable ? (
            <button
              type="button"
              onClick={() => {
                handleSubmit();
                setEditable(false);
              }}
              className={`bg-prim button rounded-md text-white flex items-center space-x-2 ${
                !editable && "opacity-50 cursor-not-allowed"
              }`}
              disabled={!editable}
            >
              {" "}
              <TiTick /> <span>Save</span>
            </button>
          ) : (
            <button
              onClick={() => {
                setEditable(true);
              }}
              type="button"
              className="bg-prim text-white flex mr-2 gird items-center space-x-2 rounded py-[6px] px-[15px] text-sm"
              disabled={editable}
            >
              <BiEdit /> <span>Edit</span>
            </button>
          )}
        </div>
      </form>
      <div className="border-b-2 pb-10 space-y-5">
        <div className="mt-5">
          <h3 className="heading">
            Integrations{" "}
            <span className="ml-1 text-lg text-[#7C3AED]">(Coming Soon)</span>
          </h3>
        </div>
        <div>
          <p className="text-gray-700">
            Spectral analyzes your unique writing style to generate personalized
            output. The more accounts you connect, the smarter Spectral gets!
          </p>
        </div>
        <div className="max-w-[736px] grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-5">
          {integrations.map(item => (
            <div
              key={item.id}
              className="bg-white p-4 rounded-lg shadow relative"
            >
              <div className="flex items-center mb-3 space-x-2">
                <img src={item.img} className="w-6 h-6" alt={item.title} />
                <h3 className="text-lg font-500">{item.title}</h3>
              </div>
              <p className="text-neutral-600 mb-4">{item.subtext}</p>
              <button
                onClick={() => {
                  openModal(item.title);
                }}
                className={`flex items-center text-neutral-700 font-600 relative ${
                  item.title.toLowerCase() === "twitter" ||
                  item.title.toLowerCase() === "substack"
                    ? ""
                    : "disabled:opacity-50 disabled:text-neutral-400"
                }`}
                disabled={
                  item.title.toLowerCase() !== "twitter" &&
                  item.title.toLowerCase() !== "substack"
                }
              >
                Connect <FaArrowRight className="ml-1" />
              </button>
            </div>
          ))}
        </div>
        {showConnectModal && (
          <div className="fixed p-4 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-75">
            <div className="bg-white px-8 py-4 rounded-md shadow-md w-80">
              <div className="flex justify-between">
                <h2 className="text-lg font-semibold">
                  {modalTitle} Connection
                </h2>
                <button
                  className="text-gray-600 hover:text-gray-800 transition ease-in-out duration-150"
                  onClick={closeModal}
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                </button>
              </div>
              <form className="p-4" onSubmit={handleConnect}>
                <label className="block mb-2">{usernamePlaceholder}</label>
                <input
                  type="text"
                  className="w-full border px-2 py-1 mb-3"
                  value={socialHandle}
                  onChange={e => setSocialHandle(e.target.value)}
                  placeholder={`example: JohnDoe`}
                  required
                />
                <br></br>
                <button
                  type="submit"
                  className="bg-blue-500 text-white mt-3 px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Connect
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-300 text-gray-700 mt-3 ml-2 px-4 py-2 rounded-md hover:bg-gray-400"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="pb-10">
        <h3 className="text-lg font-500 pt-4">Sign Out </h3>
        <p className="text-gray-700">Log out of your account</p>
        <button
          onClick={() => {
            localStorage.clear();
            Cookies.remove("token");
            window.location.reload();
          }}
          className="flex items-center space-x-2 bg-[#FEF2F2] p-2 rounded my-3 button"
        >
          <span className="text-[#fd9d4a] px-2">Log Out</span>
        </button>
      </div>
      <div className="pb-10">
        <h3 className="text-lg font-500 pt-4">Delete Account</h3>
        <p className="text-gray-700">
          Permanently remove your account and its associated data from our
          servers. This action is not reversible.
        </p>
        <button
          onClick={handleDeleteClick}
          className="flex items-center space-x-2 bg-[#FEF2F2] p-2 rounded my-3 button"
        >
          <AiFillDelete className="text-[#BE2C2C]" />
          <span className="text-[#991B1B]">Delete</span>
        </button>
        {showModal && (
          <div className="fixed p-4 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-75">
            <div className="bg-white px-8 py-4 rounded-md shadow-md w-96">
              <div className="flex justify-between">
                <h2 className="text-lg font-semibold w-100">
                  Confirm Account Deletion
                </h2>
                <button
                  className="text-gray-600 hover:text-gray-800 text-lg transition ease-in-out duration-150"
                  onClick={handleModalClose}
                >
                  Close
                </button>
              </div>
              <p className="text-gray-700">
                Are you sure you want to delete your account? This action is not
                reversible.
              </p>
              <button
                onClick={handleDeleteConfirmed}
                className="bg-red-500 text-white py-2 px-4 mt-4 rounded"
              >
                Delete Account
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const Settings = withRouter(SettingsWithoutRouter);
export default Settings;

const integrations: IntegrationsItem[] = [
  {
    id: 1,
    title: "Substack",
    subtext: "",
    img: Substack
  },
  {
    id: 2,
    title: "Twitter",
    subtext: "",
    img: Twitter
  },
  {
    id: 2,
    title: "Transistor",
    subtext: "",
    img: Transistor
  },
  {
    id: 4,
    title: "Spotify",
    subtext: "",
    img: Spotify
  }
];
